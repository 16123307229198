<template>
    <v-modal :is-open="isOpen"
        class="w-1/2 bg-white"
        @close="close">
        <v-modal-header @close="close">
            {{ t('storyblok.campaigns') }}
        </v-modal-header>

        <p class="px-lg font-bold">{{ activeCampaign.title }}</p>

        <div class="flex flex-col items-center space-y-lg px-lg pb-20">
            <div class="prose hyphens-auto font-sans leading-none md:text-base md:leading-6">
                <sb-rich-text :document="activeCampaign.content " />
            </div>

            <div class="flex flex-col">
                <v-storyblok-component :is="activeCampaign.component"
                    :blok="activeCampaign"
                    :options="{isModal: true}"
                    @close="close" />
            </div>

            <nuxt-link
                :aria-label="$t('aria.open-page', { page: $t('storyblok.campaigns') })"
                :to="url"
                class="px-3 py-sm font-sans-caption
                    text-base leading-5 tracking-xl hover:underline xl:block"
                @click="close">
                <v-button theme="black"
                    size="base">
                    {{ buttonText }}
                </v-button>
            </nuxt-link>
        </div>
    </v-modal>
</template>

<script lang="ts" setup>
import { type DiscountsBlokComponent } from '~/types';

defineProps<{
    isOpen: boolean
    isModal?: boolean
}>();

const emit = defineEmits<{
    (event: 'close'): void
}>();

function close() {
    emit('close');
}

const { t } = useI18n();
const { t: tg } = useI18n({
    useScope: 'global',
});

const discountsContent = useStoryblokStory(`${tg('storyblok.campaigns')}`);

const activeCampaign = computed<DiscountsBlokComponent>(() => discountsContent?.value?.content.active_campaigns[0]);

const buttonText = computed(() => (activeCampaign.value?.button_text === ''
    ? t('button-default')
    : activeCampaign.value.button_text));

const { format: formatSbUrl } = useSbUrl();
const url = computed(() => formatSbUrl(activeCampaign.value.button_link?.cached_url));
</script>

<i18n lang="json">
{
    "et": {
        "button-default": "Vaata kõiki kampaaniaid"
    },
    "fi": {
        "button-default": "Katso kaikki kampanjat"
    }
}
</i18n>
